// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app\\\\components\\\\Button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app\\components\\Button.tsx");
  import.meta.hot.lastModified = "1724355293000";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { forwardRef } from 'react';
import { cn } from '~/utils/style';
const displayTypeMap = {
  primary: ['text-white', 'bg-orange-500 hover:bg-orange-600', 'border-orange-500 hover:border-orange-600'],
  secondary: ['text-zinc-900 dark:text-zinc-100', 'bg-zinc-200 hover:bg-zinc-300 dark:bg-zinc-700 dark:hover:bg-zinc-600', 'border-zinc-200 hover:border-zinc-300 dark:border-zinc-700 dark:hover:border-zinc-600'],
  ghost: ['text-white hover:text-zinc-900', 'bg-transparent hover:bg-white', 'border-transparent hover:border-white'],
  danger: ['text-white', 'bg-red-600 hover:bg-red-700', 'border-red-600 hover:border-red-700']
};
export const Button = forwardRef(_c = ({
  className,
  displayType = 'primary',
  disabled,
  ...rest
}, ref) => <button className={cn('border-4', 'rounded', 'uppercase', 'font-bold', 'tracking-widest', 'py-[.5em] px-[1em]', disabled && 'cursor-not-allowed opacity-60', displayTypeMap[displayType].join(' '), className)} disabled={disabled} {...rest} ref={ref} />);
_c2 = Button;
Button.displayName = 'Button';
export const ButtonLink = forwardRef(_c3 = ({
  className,
  displayType = 'primary',
  ...rest
}, ref) =>
// eslint-disable-next-line jsx-a11y/anchor-has-content
<Link className={cn('inline-block', 'border-4', 'rounded', 'uppercase', 'font-bold', 'tracking-widest', 'py-[.5em] px-[1em]', displayTypeMap[displayType].join(' '), className)} {...rest} ref={ref} />);
_c4 = ButtonLink;
ButtonLink.displayName = 'Button';
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "Button$forwardRef");
$RefreshReg$(_c2, "Button");
$RefreshReg$(_c3, "ButtonLink$forwardRef");
$RefreshReg$(_c4, "ButtonLink");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;